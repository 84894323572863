import first from 'lodash/first'
import PropTypes from 'prop-types'
import React from 'react'
import tw, { css } from 'twin.macro'
import { StyleType, SVGType } from '../utils/prop-types'

const Logo = ({ logo }) => (
  <div
    key={logo.url}
    css={css`
      height: 5.5rem;
      ${tw`w-48 flex items-center justify-center px-4 lg:(px-8 w-64)`}
    `}
  >
    <img src={logo.url} css={tw`max-h-full`} alt="" />
  </div>
)

Logo.propTypes = {
  logo: SVGType,
}

const chunk = (a, n) => {
  const array = a.slice()
  let i = n
  const result = []
  while (array.length) {
    result.push(array.splice(0, Math.ceil(array.length / i)))
    i -= 1
  }
  return result
}

const LogoSlider = ({ logos, desktopRowCount, mobileRowCount, style }) => {
  const desktopGroups = chunk(logos, desktopRowCount)
  const mobileGroups = chunk(logos, mobileRowCount)
  const desktopLoops = Math.ceil(15 / first(desktopGroups).length) + 1
  const mobileLoops = Math.ceil(5 / first(mobileGroups).length) + 1

  return (
    <div css={[tw`w-full overflow-hidden`, style]}>
      {desktopGroups.map((group, i) => (
        <div
          key={i} // eslint-disable-line react/no-array-index-key
          css={css`
            @keyframes scroll-${i} {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(calc(${group.length} * -15rem));
              }
            }

            ${tw`hidden lg:(flex items-center mt-20)`}
            animation: scroll-${i} ${desktopLoops * group.length}s linear infinite;
            animation-direction: ${i % 2 === 0 ? 'normal' : 'reverse'};
            width: calc(15rem * ${group.length * desktopLoops});
            &:hover {
              animation-play-state: paused;
            }
          `}
        >
          {Array.from({ length: desktopLoops }, () => group)
            .flat()
            .map((logo, j) => (
              <Logo key={j} logo={logo} />
            ))}
        </div>
      ))}
      {mobileGroups.map((group, i) => (
        <div
          key={i} // eslint-disable-line react/no-array-index-key
          css={css`
            @keyframes scroll-mobile-${i} {
              0% {
                transform: translateX(0);
              }
              100% {
                transform: translateX(calc(${group.length} * -12rem));
              }
            }
            ${tw`flex items-center mt-16 lg:(hidden)`}
            animation: scroll-mobile-${i} ${mobileLoops * group.length}s linear infinite;
            animation-delay: ${i === 2 ? '-4s' : '-3s'};
            animation-direction: ${i % 2 === 0 ? 'normal' : 'reverse'};
            width: calc(12rem * ${group.length * mobileLoops});
            &:hover {
              animation-play-state: paused;
            }
          `}
        >
          {Array.from({ length: mobileLoops }, () => group)
            .flat()
            .map((logo, j) => (
              <Logo key={j} logo={logo} />
            ))}
        </div>
      ))}
    </div>
  )
}

LogoSlider.defaultProps = {
  desktopRowCount: 1,
  mobileRowCount: 2,
}

LogoSlider.propTypes = {
  logos: PropTypes.arrayOf(SVGType).isRequired,
  desktopRowCount: PropTypes.number,
  mobileRowCount: PropTypes.number,
  style: StyleType,
}

export default LogoSlider
