import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'

const LinkBanner = ({ title, callToAction, style }) => {
  return (
    <div css={[global`container`, tw`flex flex-col items-start`, style]}>
      <Heading headingType="h3" content={title} style={tw`font-bold text-primary-500`} />
      <Button
        style={tw`mt-12`}
        type="primary"
        size="base"
        theme="navy-cyan"
        label={callToAction.label}
        link={callToAction.link}
      />
    </div>
  )
}

LinkBanner.propTypes = {
  title: HTMLType,
  callToAction: LinkType,
  style: StyleType,
}

export default LinkBanner
