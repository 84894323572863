import React from 'react'
import Overlay from './overlay'
import Rectangle from './rectangle'

export const overlays = {
  hero: (
    <>
      <Overlay visibility="desktop" position="left" width={84} height={140}>
        <Rectangle x={0} y={56} width={28} height={28} fill="navy" />
        <Rectangle x={28} y={84} width={56} height={56} fill="white" />
      </Overlay>
      <Overlay visibility="desktop" position="right" width={266} height={280}>
        <Rectangle x={0} y={252} width={168} height={28} fill="yellow" />
      </Overlay>
      <Overlay visibility="desktop" position="bottom-right" width={520} height={256}>
        <Rectangle x={0} y={0} width={56} height={56} fill="white" />
        <Rectangle x={56} y={56} width={168} height={84} fill="navy" />
      </Overlay>
      <Overlay visibility="mobile" position="left" width={69.73} height={51.58}>
        <Rectangle x={37} y={0} width={32.73} height={31.58} fill="navy" />
        <Rectangle x={17} y={31.58} width={20} height={20} fill="white" />
      </Overlay>
      <Overlay visibility="mobile" position="bottom-left" width={224} height={96}>
        <Rectangle x={124} y={0} width={100} height={20.26} fill="yellow" />
      </Overlay>
      <Overlay visibility="mobile" position="right" width={20} height={300}>
        <Rectangle x={0} y={280} width={20} height={20} fill="navy" />
      </Overlay>
    </>
  ),
}
